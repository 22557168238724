<template>
  <div class="home ">
    <div>
      <el-carousel style="width: 100%;" height="500px" class="custom-swiper" arrow="never" :autoplay="true">
        <el-carousel-item>
          <div class="zzt-slogan">
            <div class="banner">
              <div class="banner-box">
                <div class="banner-text">
                  <p class="title animate__animated animate__fadeInLeftBig">科技大数据场景应用领先者</p>
                  <div class="sub-title animate__animated animate__fadeInRightBig">
                    <p>国家知识产权局知识产权出版社在金融领域场景应用独家战略共建单位</p>
                    <p>聚焦构建中国400万家高成长性科技企业成长基因库</p>
                    <p>拥有独创知识产权价值在线评估模型、企业科技力评估模型，高价值科技企业识别与评估模型</p>
                    <p>为银行、投资机构等实现数字化科技金融、智慧科企服务等提供数据支撑与科技赋能</p>
                  </div>
                  <!-- <div class="desc-list animate__animated animate__fadeInUp">
                    <div class="desc-item">
                      <div class="top">5亿+</div>
                      <div class="bottom">知识产权大数据</div>
                    </div>
                    <div class="line"></div>
                    <div class="desc-item">
                      <div class="top" style="height: 45px; line-height: 45px;">340+</div>
                      <div class="bottom">数据种类</div>
                    </div>
                    <div class="line" style="margin-right: 30px;"></div>
                    <div class="desc-item">
                      <div class="top">200万+</div>
                      <div class="bottom">服务中国高价值科技企业</div>
                    </div>
                    <div class="line" style="margin: 0 30px;"></div>
                    <div class="desc-item">
                      <div class="top" style="font-size: 26px;">三项核心能力</div>
                      <div class="bottom" style="font-size: 26px;">四大基础产品</div>
                    </div>
                  </div> -->
                </div>
                <img class="gif" src="https://images.zzt.com.cn/ox/2022/12/07/banner.gif" width="315">
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="hot-product-pate">
            <div class="hot-product-pate-box">
              <div class="hot-product-pate-text">
                <p class="title animate__animated animate__fadeInLeftBig">深眸·科技企业成长性与科技力评测系统</p>
                <p class="sub-title animate__animated animate__fadeInRightBig">
                  由中知数通、国家知识产权局知识产权出版社联合打造<br />通过分析挖掘企业科技情报、知识产权、人才、产业等大数据<br />评估企业的成长潜力、量化企业的科技实力，动态追踪企业赛道领域位势<br />核心应用于科技金融产品的企业市场洞察分析、科创金融业务中的目标企业与种子客户精准圈选
                </p>
                <div class="btn-container animate__animated animate__fadeInUp">
                  <p class="login-btn" @click="experience('login')">{{ isLogin ? '立即使用' : '立即登录' }}</p>
                  <el-popover ref="popover" placement="right" width="150" trigger="hover">
                    <div id="img-container">
                      <img src='https://images.zzt.com.cn/gnW/2024/04/26/wechat-code.png' style="width: 110px;">
                    </div>
                    <p id="code">深眸小程序</p>
                  </el-popover>
                  <el-button v-popover:popover class="exp-btn">
                    <span>体验小程序</span>
                  </el-button>
                </div>
              </div>
              <div class="hot-product-pate-img" id="hot-product-pate-img"></div>
            </div>
          </div>
        </el-carousel-item>
        <!-- <el-carousel-item>
          <div class="recent-updates">
            <div class="recent-updates-box">
              <div class="btn-container animate__animated animate__fadeInUp">
                <a class="login-btn" @click="getDetail">了解详情</a>
              </div>
            </div>
          </div>
        </el-carousel-item> -->
        <el-carousel-item>
          <div class="recent-updates" @click="getDetail">
            <div class="recent-updates-box">
              <div class="btn-container animate__animated animate__fadeInUp">
                <!-- <a class="login-btn" @click="getDetail">了解详情</a> -->
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <section class="five-domains-four-chains animate__animated animate__fadeIn">
      <h1 class="title">“五域-四链”数据生态</h1>
      <div class="content">
        <div class="card-main">
          <div class="card-item hover_effect" v-for="(item, index) in domainsChains" :key="index">
            <p class="card-item-title">{{ item.title }}</p>
            <p class="card-item-desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="product-matrix animate__animated animate__fadeIn">
      <h1 class="title" style="color: #FFFFFF;">产品矩阵</h1>
      <div class="content">
        <div class="card-main">
          <div class="card-item hover_effect" v-for="(item, index) in productMatrix" :key="index"
            :style="`background-image: url('${item.bgImage}');`">
            <div class="card-item-title">
              {{ item.title }}
            </div>
            <p class="card-item-desc" v-html="item.desc"></p>
            <div class="card-item-button" v-if="item.btnText" @click="item.btnEven">{{ item.btnText }}</div>
          </div>
        </div>
      </div>
    </section>
    <section class="service-innovation animate__animated animate__fadeIn">
      <h1 class="title">科创产品与服务创新</h1>
      <div class="content">
        <div class="card-main">
          <div class="card-item hover_effect" v-for="(item, index) in serviceInnovation" :key="index"
            :style="`background-image: url('${item.bgImage}')`">
            <p class="card-item-title">{{ item.title }}</p>
            <div class="card-item-desc">
              <div class="line-item" v-for="ite in item.desc">
                <p>{{ ite }}</p>
              </div>
            </div>
            <div class="card-item-tag">
              <p v-for="its in item.tagList" :key="its">{{ its }}</p>
            </div>
            <p class="card-item-keyProducts">{{ item.keyProducts }}</p>
          </div>
        </div>
        <div class="btn-box">
          <p class="login-btn" @click="learnMore">了解更多</p>
        </div>
      </div>
    </section>
    <section class="strategic-cooperation-unit animate__animated animate__fadeIn">
      <h1 class="title">战略合作单位</h1>
      <div class="content">
        <div class="card-main">
          <vue-seamless-scroll :data="cooperativeUnitsList" :class-option="classOption" class="warp">
            <ul class="ul-item">
              <li class="li-item" v-for="(item, index) in cooperativeUnitsList" :key="index">
                <img :src="item">
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll/src';
import lottie from 'lottie-web'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 3,
        direction: 2,
      },
      domainsChains: [
        {
          title: '建立五大主题域',
          desc: '机构、人才、技术、区域、产业',
        }, {
          title: '构建四大关系链',
          desc: '产业链、创新链、人才链、资金链',
        }, {
          title: '“五域-四链”数据底座',
          desc: '打造科技企业成长基因生态',
        }
      ],
      productMatrix: [
        {
          title: '深眸',
          desc: '评估企业的成长潜力、量化企业的科技实力，动态追踪企业赛道领域位势；</br>核心应用于科技金融产品的企业市场洞察分析、科创金融业务中的目标企业与种子客户精准圈选。',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/28/card-bg1.png',
          btnText: '前往使用',
          btnEven: () => {
            this.$router.push('/patE')
            window.scrollTo(0, 0, 'smooth')
          },
        },
        {
          title: '瞰见',
          desc: '从宏观视角切入，从不同区域、不同产业挖掘专利价值、关键人才和高潜力企业。</br>帮助企业和机构更好地了解市场、把握机会、优化决策。',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/28/card-bg2.png',
          btnText: '前往使用',
          btnEven: () => {
            this.$router.push('/aerialView')
            window.scrollTo(0, 0, 'smooth')
          },
        },
        {
          title: '专利导航',
          desc: '围绕科技企业的技术节点，多手段保护企业技术领先性，完善企业技术攻防、提升科技企业专利管理、管理、运营的能力，从而指导和促进企业的发展。',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/28/card-bg3.png',
          btnText: '前往使用',
          btnEven: () => {
            this.$router.push('/patentNavigation')
            window.scrollTo(0, 0, 'smooth')
          },
        },
        {
          title: '数据服务平台',
          desc: '以科技企业为核心，机构、人才、技术、区域、产业五大主题域，人才链、技术链、产业链、创新链四大关系链，形成“五域-四链“的科技企业成长基因库和服务体系。',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/28/card-bg4.png',
          btnText: '前往使用',
          btnEven: () => {
            this.$router.push('/bigData')
            window.scrollTo(0, 0, 'smooth')
          },
        }
      ],
      cooperativeUnitsList: [
        // 顺序：国家科技风险开发事业中心 unit8、平安银行unit7、中信银行unit6、浙商银行unit10、南京银行unit9、科融通unit4、阿里资产unit5、出版社unit1、中商所unit2、万丈unit3
        'https://images.zzt.com.cn/gnW/2024/04/30/unit8.png',
        'https://images.zzt.com.cn/ks/2023/04/23/unit7.png',
        'https://images.zzt.com.cn/ks/2023/04/23/unit6.png',
        'https://images.zzt.com.cn/gnW/2024/04/30/unit10.png',
        'https://images.zzt.com.cn/gnW/2024/04/30/unit9.png',
        'https://images.zzt.com.cn/ks/2023/04/23/unit4.png',
        'https://images.zzt.com.cn/ks/2023/04/23/unit5.png',
        'https://images.zzt.com.cn/gnW/2024/04/30/unit1.png',
        'https://images.zzt.com.cn/gnW/2024/04/30/unit2.png',
        'https://images.zzt.com.cn/gnW/2024/04/30/unit3.png',
      ],
      mainPowerList: [
        {
          title: '专利价值评估模型',
          // desc: '实现专利的线上智能评估</br>量化专利得分及估值</br>成本低廉，响应快速',
          img: 'https://images.zzt.com.cn/ox/2022/12/06/main-power1.png'
        },
        {
          title: '企业科技力评测模型',
          // desc: '分析挖掘知识产权大数据</br>中国企业科技实力量化</br>多维度智能评估',
          img: 'https://images.zzt.com.cn/ox/2022/12/06/main-power3.png'
        },
        {
          title: '高价值科技企业挖掘识别模型',
          // desc: '从宏观至微观逐层扫描</br>形成科技企业的动态画像</br>预测企业的市场价值走向',
          img: 'https://images.zzt.com.cn/ox/2022/12/06/main-power2.png'
        }
      ],
      serviceInnovation: [
        {
          title: '科创企业评估',
          // desc: ['中知数通与中信银行北京分行共同探索科技大数据驱动科创产品与服务创新，共建“科技成果转化贷”、中小微科企信贷产品创新模式。', '在中信银行的客户准入、筛选、预授信打分卡设计等核心环节，赋能中信银行在贷前-贷中-贷后环节高效识别科创企业的技术水平与成长性'],
          desc: ['中知数通与某股份制银行分行共同探索科技大数据驱动科创产品与服务创新，共建信贷产品创新服务模式。', '在客户准入、筛选、预授信打分卡设计等核心环节，赋能银行贷前-贷中-贷后环节高效识别科创企业的技术水平与成长性。'],
          tagList: ['底层科创数据构建', '动态模型调整', '准入客群画像', '线上化预评估工具'],
          keyProducts: '关键产品：深眸、数据服务平台',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/case-card-bg1.png'
        },
        {
          title: '数智赋能',
          desc: ['中知数通为平安银行科创金融业务提供多样化数智赋能，助力搭建国内首款纯数字化专利贷产品、搭建“企业成长性评估系统”，提供多项科创数据及评价模型，以数智能力作为驱动业务发展引擎和坚实底座'],
          tagList: ['科创贷系列方案产品设计', '共建产品评分卡', '监测贷中贷后风险', '监管报数及客群管理'],
          keyProducts: '关键产品：专利评估模型、科技力评估模型、数据服务平台',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/case-card-bg2.png'
        }
      ],
      prodList: [
        {
          name: '深眸',
          desc: '中国企业专利评价与科技力预测系统',
          icon: 'https://images.zzt.com.cn/ox/2022/12/07/prod-icon1.png',
          link: 'https://pate.zzt.com.cn/login'
        },
        {
          name: '小蓝书·报告中心',
          desc: '报告中心持续上架各类科创报告，全面解析科创金融产业动向',
          icon: 'https://images.zzt.com.cn/ox/2022/12/07/prod-icon2.png'
        },
        {
          name: '深蓝',
          desc: '中国高价值科技企业挖掘识别系统',
          icon: 'https://images.zzt.com.cn/ox/2022/12/07/prod-icon3.png'
        },
        {
          name: 'OpenAPI',
          desc: '知识产权大数据开放数据接口平台',
          icon: 'https://images.zzt.com.cn/ox/2022/12/07/prod-icon4.png'
        }
      ],
      dynamicList: [
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/08/dynamic_1.png',
          title: '中国企业科技力量化评测联合课题组成立',
          detail: '中国技术市场协会、中国知识产权研究会、新华社《经济参考报》、知识产权出版社与中知数通（北京）信息技术……',
          time: '2022-11-28',
          link: 'https://mp.weixin.qq.com/s/MvT6Yy7sAwpuHwxp1m52wQ'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/07/dynamic2.png',
          title: '国内首个知识产权数字化流通生态平台建设启动',
          detail: '国家知识产权局直属单位和知识产权信息服务专业机构知识产权出版社有限责任公司与在线资产交易平台阿里资产达成数……',
          time: '2022-09-09',
          link: 'http://www.jjckb.cn/2022-09/09/c_1310661059.htm'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/07/dynamic3.png',
          title: '专利大数据应用系统“深眸”上线',
          detail: '由国家知识产权局知识产权出版社、中知数通（北京）信息技术有限公司联合打造的专利大数据应用系统“深眸”日前正式……',
          time: '2022-04-28',
          link: 'http://www.jjckb.cn/2022-04/28/c_1310577086.htm'
        }
      ],
    }
  },
  computed: {
    ...mapState(['isLogin'])
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById('hot-product-pate-img'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://images.zzt.com.cn/ox/2022/12/26/data.json'
    })
  },
  methods: {
    toPage(link) {
      window.open(link, '_blank')
    },
    experience(type) {
      let url = ''
      switch (type) {
        case 'login':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/search`
          break;
        case 'technology':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/ability/tecpart?id=573481777151217664`
          break;
        case 'track':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/ability/track/ipc?id=573481777151217664&ipcId=73931`
          break;
        case 'benchmarking':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/ability/mark?id=573481777151217664`
          break;
      }
      if (this.$store.state.token) {
        url = `${url}${url.includes('?') ? '&' : '?'}token=${this.$store.state.token}`
        window.open(url)
      } else {
        this.$router.push(`/login/?redirect=${url}`)
      }
    },
    getDetail() {
      window.open('https://mp.weixin.qq.com/s/KflnbCz0fj2sBjD1eQCFzQ?token=409551773&lang=zh_CN', '_blank')
    },
    learnMore() {
      this.$router.push('/financialService')
      window.scrollTo(0, 0, 'smooth')
    }
  }
}
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}

$theme-color: #4088Eb;

.Button {
  display: inline-block;
  width: Translate(140px);
  height: Translate(36px);
  border: Translate(1px) solid #fff;
  font-size: Translate(14px);
  color: #ffffff;
  text-align: center;
  line-height: Translate(36px);
  border-radius: Translate(4px);
  background-color: #4088eb;

  &:hover {
    background: #337ee5;
    color: #fff;
    border: Translate(1px) solid #fff;
  }
}

.home {
  background-color: #fff;
}

.zzt-slogan {
  background-color: rgb(224, 237, 251);

  .banner {
    width: 100%;
    min-width: $container-width;
    max-width: Translate(1920px);
    height: Translate(500px);
    background: url("https://images.zzt.com.cn/ox/2022/12/06/home-banner.png") no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;

    &-box {
      width: $container-width;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }

    &-text {
      text-align: left;

      .title {
        position: absolute;
        top: Translate(155px);
        left: 0;
        font-size: Translate(40px);
        font-weight: 500;
      }

      .sub-title {
        position: absolute;
        top: Translate(225px);
        left: 0;
        font-size: Translate(16px);
        color: #666;

        p {
          line-height: Translate(30px);

          &::before {
            content: "";
            display: inline-block;
            background: url(https://images.zzt.com.cn/gnW/2024/04/28/select-icon.png) no-repeat;
            width: Translate(16px);
            height: Translate(16px);
            background-position: center center;
            background-size: cover;
            margin-right: Translate(8px);
            position: relative;
            top: Translate(3px);
          }
        }
      }

      .desc-list {
        color: #4088EB;
        font-weight: 500;
        position: absolute;
        top: 745px;
        left: 0;
        display: flex;

        .line {
          width: 3px;
          height: Translate(70px);
          background-color: #4088EB;
          margin: 0 Translate(60px);
        }

        .desc-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;

          .top {
            font-size: Translate(34px);
          }

          .bottom {
            font-size: Translate(16px);
          }
        }
      }
    }

    .gif {
      position: absolute;
      right: 110px;
      top: 160px;
    }
  }
}

.exp-btn {
  @include Button(162px, 42px, #fff, 14px, #4088eb);
  border: Translate(1px) solid #4088eb;

  &:hover {
    background: #4088eb;
    color: #fff;
    border: 1px solid #4088eb;
  }

  margin-left: Translate(15px);
}


.hot-product-pate {
  width: 100%;
  min-width: $container-width;
  height: Translate(500px);
  background: url('https://images.zzt.com.cn/ox/2022/12/23/prod-banner-bg.png') no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;

  // background-image: linear-gradient(-83deg, #68BAFF 0%, #4088EB 100%);
  &-box {
    width: $container-width;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }

  &-text {
    text-align: left;
    color: #fff;

    .title {
      position: absolute;
      top: Translate(110px);
      left: 0;
      color: #333;
      font-size: Translate(40px);
      font-weight: 500;
    }

    .sub-title {
      position: absolute;
      top: Translate(180px);
      left: 0;
      color: #666;
      font-size: Translate(16px);
      line-height: Translate(34px);
    }

    .btn-container {
      width: Translate(338px);
      position: absolute;
      top: Translate(340px);
      left: 0;
      display: flex;
      // justify-content: space-between;
      justify-content: center;

    }
  }

  &-img {
    width: Translate(294px);
    position: absolute;
    right: Translate(50px);
    top: Translate(46px);
  }
}

.login-btn {
  @include Button(160px, 40px, #4088eb, 14px, #fff);
  border: Translate(1px) solid #4088eb;

  &:hover {
    background: #337ee5;
  }
}

.recent-updates {
  width: 100%;
  min-width: $container-width;
  height: Translate(500px);
  // background: url('https://images.zzt.com.cn/gnW/2024/04/30/news-banner3.png') no-repeat;
  // background: url('https://images.zzt.com.cn/officialWebsite/0620-swipe-nes2.png') no-repeat;
  background: url('https://images.zzt.com.cn/cdn_file/2024-12-16/1203-news.png') no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  cursor: pointer;

  &-box {
    width: $container-width;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }

  &-text {
    text-align: left;
    color: #fff;

    .title {
      position: absolute;
      top: Translate(155px);
      left: 0;
      color: #fff;
      font-size: Translate(40px);
      font-weight: 500;
    }

    .sub-title {
      position: absolute;
      top: Translate(225px);
      left: 0;
      color: #fff;
      font-size: Translate(16px);
      line-height: Translate(34px);
      width: Translate(774px);
    }
  }

  .btn-container {
    width: 100%;
    position: absolute;
    top: Translate(340px);
    left: 0;
    display: flex;
    justify-content: center;
  }
}

section {
  padding-top: Translate(74px);

  .content {
    width: $container-width;
    margin: 0 auto;

    .img-container {
      display: flex;
      justify-content: space-between;

      img {
        margin-left: Translate(20px);
      }
    }
  }

  .title {
    font-size: Translate(36px);
    color: #333333;
    font-weight: 500;
    margin: 0 auto Translate(46px);
    min-width: $container-width;
  }
}

.five-domains-four-chains {
  height: Translate(1000px);
  background: url(https://images.zzt.com.cn/cdn_file/2025-03-25/five-domains-four-chains-bg.png) no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  box-sizing: border-box;

  .content {
    .card-main {
      display: flex;
      justify-content: center;
      height: Translate(130px);

      .card-item {
        width: Translate(300px);
        height: Translate(70px);
        background: url('https://images.zzt.com.cn/gnW/2024/04/28/domains-chains-card-bg.png') no-repeat;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .card-item-title {
          font-size: Translate(18px);
          color: #333333;
          font-weight: 500;
          margin-bottom: Translate(4px);
        }

        .card-item-desc {
          font-size: Translate(14px);
          color: #666666;
        }

        &:nth-child(-n+2) {
          // background-color: red;
          margin-right: Translate(68px);
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: Translate(-43px);
            display: inline-block;
            width: Translate(26px);
            height: Translate(22px);
            background: url('https://images.zzt.com.cn/gnW/2024/04/28/right-icon.png') no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }
  }
}

.product-matrix {
  height: Translate(644px);
  background: url('https://images.zzt.com.cn/gnW/2024/04/28/product-matrix-bg.png') no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;

  .content {
    .card-main {
      display: flex;
      justify-content: space-between;

      .card-item {
        width: Translate(278px);
        height: Translate(374px);
        text-align: left;
        background-position: center center;
        background-size: cover;
        box-sizing: border-box;
        padding: 0 Translate(20px);
        position: relative;

        .card-item-title {
          font-size: Translate(20px);
          color: #333333;
          font-weight: 500;
          margin: Translate(24px) 0 Translate(28px) 0;
          position: relative;

          &::after {
            content: '';
            width: Translate(24px);
            height: Translate(4px);
            background: #ACD1FA;
            position: absolute;
            left: 0;
            bottom: Translate(-10px);
          }
        }

        .card-item-desc {
          font-size: Translate(14px);
          color: #333333;
          text-align: justify;
          line-height: Translate(24px);
        }

        .card-item-button {
          position: absolute;
          background-image: linear-gradient(270deg, #12C39B 0%, #4088EB 100%);
          border-radius: Translate(4px);
          font-size: Translate(16px);
          color: #FFFFFF;
          text-align: center;
          font-weight: 400;
          padding: Translate(7px) Translate(20px);
          left: Translate(20px);
          bottom: Translate(24px);
          cursor: pointer;
        }
      }
    }
  }
}

.service-innovation {
  height: Translate(750px);
  background: url('https://images.zzt.com.cn/gnW/2024/04/28/service-innovation-bg.png') no-repeat;
  box-sizing: border-box;
  background-position: center center;
  background-size: cover;

  .content {
    .card-main {
      display: flex;
      justify-content: space-between;

      .card-item {
        width: Translate(588px);
        height: Translate(404px);
        box-sizing: border-box;
        padding: 0 Translate(30px);
        text-align: left;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0 4px 8px 0 rgba(166, 166, 166, 0.10);

        .card-item-title {
          font-size: Translate(24px);
          color: #333333;
          text-align: center;
          line-height: Translate(34px);
          font-weight: 500;
          margin-top: Translate(31px);
          margin-bottom: Translate(8px);
        }

        .card-item-desc {
          height: Translate(165px);

          .line-item {
            display: flex;

            p {
              font-size: Translate(14px);
              color: #666666;
              line-height: Translate(24px);
              font-weight: 500;
              margin-bottom: Translate(6px);
              width: Translate(514px);
            }

            &::before {
              content: '';
              display: inline-block;
              width: Translate(4px);
              height: Translate(4px);
              border-radius: Translate(2px);
              background: #687492;
              margin-right: Translate(10px);
              position: relative;
              top: Translate(10px);
            }
          }
        }

        .card-item-tag {
          display: flex;
          flex-wrap: wrap;
          width: Translate(514px);

          p {
            width: Translate(200px);
            margin-right: Translate(40px);
            margin-bottom: Translate(8px);
            font-size: Translate(16px);
            color: #333333;
            font-weight: 500;

            &::before {
              content: "";
              display: inline-block;
              background: url(https://images.zzt.com.cn/gnW/2024/04/28/select-icon.png) no-repeat;
              width: 1.00053rem;
              height: 1.00053rem;
              background-position: center center;
              background-size: cover;
              margin-right: Translate(8px);
            }
          }
        }

        .card-item-keyProducts {
          font-size: Translate(16px);
          color: #4088EB;
          font-weight: 500;
          margin-top: Translate(45px)
        }
      }
    }

    .btn-box {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: Translate(42px);
    }
  }

}

.strategic-cooperation-unit {
  height: Translate(424px);
  background: #FFFFFF;
  box-sizing: border-box;

  .content {
    width: 100%;
    display: flex;
    justify-content: center;

    .card-main {
      width: $container-width;
      overflow: hidden;
      height: Translate(130px);

      .card-item {
        width: Translate(285px);
        height: Translate(130px);
        float: left;
        margin-left: Translate(24px);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.Button {
  display: inline-block;
  width: Translate(140px);
  height: Translate(36px);
  border: Translate(1px) solid #fff;
  font-size: Translate(14px);
  color: #ffffff;
  text-align: center;
  line-height: Translate(36px);
  border-radius: Translate(4px);
  background-color: #4088eb;

  &:hover {
    background: #337ee5;
    color: #fff;
    border: Translate(1px) solid #fff;
  }
}

.login-btn {
  @include Button(160px, 40px, #4088eb, 14px, #fff);
  border: Translate(1px) solid #4088eb;

  &:hover {
    background: #337ee5;
  }
}

#img-container {
  width: Translate(130px);
  height: Translate(130px);
  margin-left: Translate(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#code {
  text-align: center;
  font-size: Translate(12px);
  color: #333333;
  font-weight: 400;
  padding-bottom: 15px;
}

.warp {
  //width: Translate(2850px);
  height: Translate(130px);

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      .li-item {
        width: Translate(285px);
        height: Translate(130px);
        float: left;
        margin-left: Translate(24px);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.custom-swiper .el-carousel__indicators--horizontal {
  bottom: 48px;
}

.custom-swiper .el-carousel__indicators {
  .el-carousel__indicator{
    padding: 0px 0px;
    &:nth-child(2){
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.custom-swiper .el-carousel__indicator--horizontal .el-carousel__button {
  width: 8px;
  height: 8px;
  background: #BBBBBB;
  border-radius: 50%;
}

.custom-swiper .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 8px;
  height: 8px;
  background: #4088EB;
  border-radius: 50%;
}
</style>
